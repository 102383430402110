import { bindable, inject }           from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { PLATFORM }                   from 'aurelia-pal';
import { BaseListViewModel }          from 'base-list-view-model';
import { FilterFormSchema }           from 'modules/management/report-headers/filter-form-schema';
import { AppContainer }               from 'resources/services/app-container';
import { Downloader }                 from 'resources/services/downloader';
import { ReportCategoriesRepository } from 'modules/management/report-headers/services/categories-repository';

@inject(AppContainer, ReportCategoriesRepository, I18N, Downloader, FilterFormSchema)
export class ListReportHeaders extends BaseListViewModel {

    listingId = 'management-report-headers-listing';

    @bindable headerTitle    = 'listing.management.report-headers';
    @bindable newRecordRoute = 'management.report-headers.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/report-headers/categories.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.report-headers.manage',
            'management.report-headers.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository: this.repository,
            edit:       {
                action:  (row) => this.appContainer.router.navigateToRoute('management.report-categories.index', { categoryId: row.id }),
                visible: () => this.appContainer.authenticatedUser.can(['management.report-headers.manage', 'management.report-headers.view']),
            },
            options:    [],
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'name',
                    name:  'report_category_translations.name',
                    title: 'form.field.name',
                },
            ],
        };
    }

}
