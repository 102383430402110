import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ReportHeadersRepository extends BaseRepository {
    baseUrl = 'management/report-headers';
    setCategory(category) {
        this.baseUrl = `management/report-headers/${category}`;
    }
}

